import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectEffectiveValues} from '@hkm/components/Menu/PropertySelector/domain/selectors';

export const useIsMobilePostingConfigured = () => {
  const effectiveValues = useSelector(selectEffectiveValues);
  
  const isMobilePostingConfigured = useMemo(() =>
    !!(
      effectiveValues?.enabledHousekeepingMobilePostingCashier &&
      effectiveValues?.mobilePostingItems?.length &&
      effectiveValues?.enabledHousekeepingMobilePostingEmails
    ),
    [effectiveValues?.enabledHousekeepingMobilePostingCashier, effectiveValues?.enabledHousekeepingMobilePostingEmails, effectiveValues?.mobilePostingItems?.length]);

  return {
    isMobilePostingConfigured
  }
}
